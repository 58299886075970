<template>
  <div>
    <div class="wrapper">
      <Head />
      <!-- 內容 -->
      <section class="main case_list" v-if="Member">
        <div class="container small">
          <div class="title">
            <h1>{{ forView(CaseServiceListData.CaseInfo.CaseInfoId) }} 勤務登記列表</h1>
          </div>
          <hr />
          <div class="text_none show">
            <div class="CheckAll" style="justify-content: end;">
              <router-link :to="{ path: '/CaseService', query: { CIId: CaseServiceListData.CaseInfo.CIId, CISLId: -1 } }"
                tag="button" v-if="(CaseServiceListData.CaseInfo.Status == 0 || CaseServiceListData.CaseInfo.Status == 1) &&
                  CaseServiceListData.CaseInfo.Official_Status == 6 &&
                  (CaseServiceListData.Member.Role.indexOf('外勤人員') != -1 && CaseServiceListData.CaseInfo.Outsider == CaseServiceListData.Member.MId)
                ">新增勤務登記</router-link>
            </div>
            <div class="box show" v-for="(CaseInfoServiceLog, index) in CaseInfoServiceLogs" :key="index"
              v-if="CaseInfoServiceLogs">
              <ul class="text_box">
                <li class="text">
                  <p>登記人</p>
                  <p>{{ CaseInfoServiceLog.Member.Name }}({{ CaseInfoServiceLog.Member.Account }})</p>
                </li>
                <li class="text">
                  <p>出勤日期</p>
                  <p>{{ CaseInfoServiceLog.ServiceTime | timeString("YYYY-MM-DD HH:mm:ss") }}</p>
                </li>
                <li class="text">
                  <p>概述</p>
                  <p>{{ CaseInfoServiceLog.Summary }}</p>
                </li>
                <li class="text">
                  <p>狀態</p>
                  <p v-if="CaseInfoServiceLog.Status == 1">處理中</p>
                  <p v-if="CaseInfoServiceLog.Status == 2">處理完成</p>
                </li>
                <li class="text">
                  <p>建立時間</p>
                  <p>{{ CaseInfoServiceLog.CreateTime | timeString("YYYY-MM-DD HH:mm:ss") }}</p>
                </li>
                <li class="text">
                  <p>打卡時間</p>
                  <p v-if="!CaseInfoServiceLog.CardTime">未打卡</p>
                  <p v-else>{{ CaseInfoServiceLog.CardTime | timeString("YYYY-MM-DD HH:mm:ss") }}</p>
                </li>
                <li class="text btn_box">
                  <p>操作</p>
                  <p>
                    <router-link :to="{
                      path: '/CaseService',
                      query: { CIId: CaseServiceListData.CaseInfo.CIId, CISLId: CaseInfoServiceLog.CISLId },
                    }" tag="button" v-if="CaseInfoServiceLog.Status == 1 && (CaseServiceListData.CaseInfo.Status == 0 || CaseServiceListData.CaseInfo.Status == 1) &&
                      CaseServiceListData.CaseInfo.Official_Status == 6 &&
                      (CaseServiceListData.Member.Role.indexOf('外勤人員') != -1 && CaseServiceListData.CaseInfo.Outsider == CaseServiceListData.Member.MId)
                    "><img src="/assets/img/icon_2.svg" alt="" />編輯</router-link>
                    <button v-if="CaseInfoServiceLog.Status == 1 && (CaseServiceListData.CaseInfo.Status == 0 || CaseServiceListData.CaseInfo.Status == 1) &&
                      CaseServiceListData.CaseInfo.Official_Status == 6 &&
                      CaseServiceListData.Member.Role.indexOf('外勤人員') != -1 &&
                      CaseServiceListData.CaseInfo.Outsider == CaseServiceListData.Member.MId
                    " @click="CheckInService(CaseInfoServiceLog.CISLId)">
                      打卡
                    </button>
                    <button v-if="(CaseServiceListData.CaseInfo.Status == 2) &&
                      (CaseServiceListData.Member.Role.indexOf('話務人員') != -1 || CaseServiceListData.Member.Role.indexOf('外勤人員') != -1)
                    " @click="gotoServiceView(CaseInfoServiceLog.CISLId)"><img src="/assets/img/icon_2.svg" />查看</button>
                  </p>
                </li>
              </ul>
            </div>
            <!-- 頁碼 -->
            <paginate :no-li-surround="false" :page-count="pageAll" :page-range="5" :margin-pages="1"
              :click-handler="loadList" :prev-text="'<'" :next-text="'>'" :prev-link-class="'page-link'"
              :next-link-class="'page-link'" :container-class="'pagination'" :page-link-class="'page-link'"
              :active-class="'active'" :active-link-class="''" :hide-prev-next="false">
            </paginate>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";
import AES from "@/utils/aes.js";
import VueCookies from "vue-cookies";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import Paginate from "vuejs-paginate";
Vue.component("paginate", Paginate);

Vue.use(VueAxios, axios);

export default {
  name: "CaseServiceList",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      Member: null,
      CaseServiceListData: null,
      CaseInfoServiceLogs: null,
      pageAll: 0,
      perPage: 5,
    }
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src +
        this.$store.state.subDirectory +
        "/Html/CaseServiceList",
        {
          params: {
            CIId: this.$route.query.CIId,
            token: $cookies.get("acm_login_token"),
          },
        }
      )
      .then((response) => {
        this.CaseServiceListData = response.data;
        if (this.CaseServiceListData.error) {
          Swal.fire({
            title: "錯誤",
            text: this.CaseServiceListData.error,
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
            willClose: () => {
              if (this.CaseServiceListData.token_error) {
                $cookies.remove("acm_login_token");
                this.$router.push({
                  name: "Login",
                });
              } else if (this.CaseServiceListData.goback) {
                this.$router.push({
                  name: "CaseList",
                });
              }
            },
          });
        } else {
          this.Member = this.CaseServiceListData.Member;
          this.loadList(1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
  },
  updated: function () {
  },
  methods: {
    loadList: function (page) {
      var that = this;
      $.ajax({
        url:
          this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/CaseInfoServiceLogList",
        type: "post",
        data: {
          CIId: this.$route.query.CIId,
          page: page,
          perPage: that.perPage,
          token: $cookies.get("acm_login_token"),
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          if (res.status == 1) {
            that.CaseInfoServiceLogs = res.CaseInfoServiceLogs;
            that.perPage = res.perPage;
            that.pageAll = Math.max(Math.ceil(res.total / that.perPage), 1);
            document.body.scrollTop = 0;
          }
        },
      });
    },
    forView(caseInfoId) {
      var sRet = "";
      if (caseInfoId && caseInfoId.length == 8 + 5) {
        sRet = caseInfoId.substr(0, 8);
        sRet += "-";
        sRet += caseInfoId.substr(8);
      } else {
        sRet = caseInfoId;
      }
      return sRet;
    },
    CheckInService: function (CISLId) {
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      let that = this;
      // 取得目前位置
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (pos) {
            const crd = pos.coords;
            $.ajax({
              url:
                that.$store.state.src +
                that.$store.state.subDirectory +
                "/Html/CheckInService",
              type: "post",
              data: {
                CISLId: CISLId,
                lat: crd.latitude,
                lng: crd.longitude,
                token: $cookies.get("acm_login_token"),
              },
              dataType: "json",
              error: function () { },
              success: function (res) {
                // 判斷結果
                if (res.ok) {
                  Swal.fire({
                    title: "打卡完成",
                    text: `${crd.latitude}, ${crd.longitude}`,
                    icon: "success",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                  });
                } else if (res.error) {
                  Swal.fire({
                    title: "錯誤",
                    text: res.error,
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                    willClose: () => {
                      if (res.token_error) {
                        $cookies.remove("acm_login_token");
                        that.$router.push({
                          name: "Login",
                        });
                      }
                    },
                  });
                }
              },
            });
          },
          function (err) {
            Swal.fire({
              title: "錯誤",
              text: `取得定位失敗(${err.code}): ${err.message}`,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          }
        );
      } else {
        Swal.fire({
          title: "錯誤",
          text: "無法取得定位",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
      }
    },
    gotoServiceView(CISLId) {
      const routeData = this.$router.resolve({ name: 'CaseServiceView', query: { CISLId: CISLId } });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>