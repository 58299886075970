import CryptoJS from 'crypto-js'

export default {
    // 加密
    encrypt(strOrignText) {
        var key = CryptoJS.enc.Utf8.parse('2e9d5fe7cf384fe2');
        var iv = CryptoJS.enc.Utf8.parse('8dd9339f517b28c7');
        var encrypted = CryptoJS.AES.encrypt(strOrignText, key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    },
    // 解密
    decrypt(strEncryptText) {
        var key = CryptoJS.enc.Utf8.parse('2e9d5fe7cf384fe2');
        var iv = CryptoJS.enc.Utf8.parse('8dd9339f517b28c7');
        var decrypted = CryptoJS.AES.decrypt(strEncryptText, key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    },
}