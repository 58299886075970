<template>
  <section class="nav">
    <div class="container">
      <nav class="navbar">
        <router-link class="navbar-brand" :to="{
          path: '/',
        }">
          <img src="/assets/img/logo_w.png" alt="" />
        </router-link>
        <h1>勤務案件管理</h1>
        <div class="log_out" type="button" v-if="IsLogin">
          <p class="name">{{ LoginName }}</p>
          <router-link :to="{
            path: '/ResetPassword',
            query: {},
          }" tag="button" style="margin-right: 10px;">變更密碼</router-link>
          <button @click="logout">
            <img src="assets/img/log_out.svg" alt="" />
            <p>登出</p>
          </button>
        </div>
      </nav>
    </div>
  </section>
</template>

<script>
export default {
  name: "Head",
  data() {
    return {
      IsLogin: $cookies.isKey("acm_login_token"),
      LoginName: $cookies.get("acm_login_name"),
    };
  },
  mounted: function () {
    // 0901 浮動按鈕
    $(".floating_btn .top button").click(function () {
      $("html,body").animate({ scrollTop: $(".nav").scrollTop() }, 800);
    });
  },
  methods: {
    logout() {
      $cookies.remove("acm_login_token");
      $cookies.remove("acm_login_name");
      this.$router.push({
        name: "Login",
      });
    },
  },
};
</script>