import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import CaseList from '../views/CaseList.vue'
import CaseEdit from '../views/CaseEdit.vue'
import CaseService from '../views/CaseService.vue'
import ForgetPassword from '../views/ForgetPassword.vue'
import ResetPasswordF from '../views/ResetPasswordF.vue'
import ResetPassword from '../views/ResetPassword.vue'
import CaseServiceView from '../views/CaseServiceView.vue'
import CaseServiceList from '../views/CaseServiceList.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/CaseList',
    name: 'CaseList',
    component: CaseList
  },
  {
    path: '/CaseEdit',
    name: 'CaseEdit',
    component: CaseEdit
  },
  {
    path: '/CaseService',
    name: 'CaseService',
    component: CaseService
  },
  {
    path: '/ForgetPassword',
    name: 'ForgetPassword',
    component: ForgetPassword
  },
  {
    path: '/ResetPasswordF/:ForgotToken?',
    name: 'ResetPasswordF',
    component: ResetPasswordF
  },
  {
    path: '/ResetPassword',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/CaseServiceView',
    name: 'CaseServiceView',
    component: CaseServiceView
  },
  {
    path: '/CaseServiceList',
    name: 'CaseServiceList',
    component: CaseServiceList
  },
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
