<template>
  <footer>
    <!-- 0901 浮動按鈕 -->
    <div class="floating_btn">
      <div class="top" type="button">
        <button>
          <img src="/assets/img/top.svg" alt="" />
          <p>TOP</p>
        </button>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>