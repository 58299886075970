<template>
  <div>
    <div class="wrapper">
      <Head />
      <!-- 內容 -->
      <section class="main">
        <div class="container">
          <div class="box login_box">
            <div class="title">
              <h1>設定新密碼</h1>
            </div>
            <div class="">
              <div class="form-group">
                <label for="">新密碼</label>
                <input type="password" class="form-control" placeholder="請輸入新密碼" v-model="password" />
              </div>
              <div class="form-group">
                <label for="">再次輸入新密碼</label>
                <input type="password" class="form-control" placeholder="請再次輸入新密碼" v-model="passwordConfirm" />
              </div>
              <div class="form-group">
                <label for="">驗證碼</label>
                <input class="form-control" placeholder="請輸入驗證碼" v-model="identifyCode_input" />
              </div>
              <div class="form-group">
                <Sidentify :identifyCode="identifyCode"></Sidentify>
                <a href="javascript:void(0)" @click="refreshCode()"><img src="/assets/img/icon-rotate.svg" alt=""
                    style="vertical-align: initial; margin-left: 6px" /></a>
                <a href="javascript:void(0)" @click="playCode()"><img src="/assets/img/icon-voice.svg" alt=""
                    style="vertical-align: initial; margin-left: 6px" /></a>
              </div>
            </div>
            <div class="btn_box">
              <button @click="send()">設定新密碼</button>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";
import Sidentify from "@/components/Identify";
import AES from "@/utils/aes.js";
import VueCookies from "vue-cookies";
import Validate from "@/utils/validate.js";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export default {
  name: "ResetPasswordF",
  components: {
    Head,
    Footer,
    Sidentify,
  },
  data() {
    return {
      ForgotToken: "",
      password: "",
      passwordConfirm: "",
      identifyCode: "0511",
      identifyCode_input: "",
      identifyCodes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      identifyCode_sound: ["0.mp3", "5.mp3", "1.mp3", "1.mp3"],
      num: 0,
    };
  },
  created: function () {
    if ($cookies.isKey("acm_login_token")) {
      this.$router.push({
        name: "CaseList",
      });
    }
    this.ForgotToken = this.$route.params.ForgotToken;
    this.refreshCode();
  },
  mounted: function () { },
  methods: {
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        var code =
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
        this.identifyCode += code;
        this.identifyCode_sound[i] = code + ".mp3";
      }
    },
    playCode() {
      var sound_path = "/voice/numvoice/zh";
      var audio = new Audio(
        sound_path + "/" + this.identifyCode_sound[this.num]
      );
      audio.play();
      let that = this;
      audio.addEventListener(
        "ended",
        function () {
          that.num++;
          if (that.num < 4) that.playCode();
          if (that.num >= 4) that.num = 0;
        },
        false
      );
    },
    send() {
      if (this.password == "") {
        Swal.fire({
          title: "錯誤",
          text: "請輸入密碼",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (this.passwordConfirm != this.password) {
        Swal.fire({
          title: "錯誤",
          text: "兩次輸入不相符, 請確認後再試一次",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      // 組合並送出
      let token = AES.encrypt(this.password + "|" + this.passwordConfirm + "|" + this.ForgotToken);
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/ResetPasswordF",
        type: "post",
        data: {
          token: token,
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            Swal.fire({
              title: "設定新密碼成功",
              text: "請使用新的密碼進行登入",
              icon: "success",
              allowOutsideClick: false,
              showConfirmButton: true,
              heightAuto: false,
              willClose: () => {
                that.$router.push({
                  name: "Login",
                });
              },
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        },
      });
    },
  },
};
</script>