<template>
  <div>
    <div class="wrapper">
      <Head />
      <!-- 內容 -->
      <section class="main case_registration" v-if="CaseInfoServiceLog" v-show="!CheckPage">
        <div class="container small">
          <div class="title">
            <h1 v-if="CaseInfoServiceLog.CISLId == -1">新增勤務登記</h1>
            <h1 v-if="CaseInfoServiceLog.CISLId != -1">編輯勤務登記</h1>
          </div>
          <div class="btn">
            <button class="add">
              值勤內容
              <div class="plus"></div>
            </button>
          </div>
          <div>
            <div class="box">
              <div class="file_box">
                <p>附加檔案</p>
                <!-- <div class="custom_file">
                  <div id="preview_progressbarTW_imgs" style="">
                  </div>
                  <div class="aa">
                    <input
                      type="file"
                      multiple="multiple"
                      class=""
                      id="progressbarTWInput"
                    />
                    <div class="text">
                      <img src="/assets/img/plus.svg" alt="" />
                      <div class="upload">
                        將檔案拖曳到這裡，或者點選此處選擇檔案上傳
                      </div>
                      <p>
                        僅支援圖片、文件或壓縮檔及MP4影片且小於10MB以下的檔案
                      </p>
                    </div>
                  </div>
                </div> -->
                <vue-dropzone ref="dropzone_files" id="dropzone" :options="dropzoneOptions"
                  v-on:vdropzone-success="vdropzone_success" v-on:vdropzone-error="vdropzone_error"></vue-dropzone>
                <div id="preview_imgs" style="" class="row mt-3">
                  <div class="col-4" v-for="(Attachment, index) in Attachments" :key="index">
                    <a :title="Attachment.Name" :href="Attachment.Url" target="_blank"><img :alt="Attachment.Name"
                        :src="Attachment.Url" style="width: 100%; height: auto" /></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="file_box">
                <p>現場查核表單</p>
                <vue-dropzone ref="dropzone_files_2" id="dropzone" :options="dropzoneOptions"
                  v-on:vdropzone-success="vdropzone_success" v-on:vdropzone-error="vdropzone_error"></vue-dropzone>
                <div id="preview_imgs" style="" class="row mt-3">
                  <div class="col-4" v-for="(InspectionForm, index) in InspectionForms" :key="index">
                    <a :title="InspectionForm.Name" :href="InspectionForm.Url" target="_blank">{{ InspectionForm.Name
                      }}</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="form">
                <div class="form-group" style="flex-basis: 100%">
                  <label for="">出勤日期<span style="color: red">*</span></label>
                  <input type="datetime-local" class="form-control" v-model="CaseInfoServiceLog.ServiceTime"
                    data-required="1" data-required-msg="請選擇出勤日期" />
                </div>
                <div class="form-group" v-if="false">
                  <label for="">狀態<span style="color: red">*</span></label>
                  <select class="form-control" v-model="CaseInfoServiceLog.Status" data-required="1"
                    data-required-msg="請選擇狀態">
                    <option v-for="(Status, index) in StatusList" :key="index" :text="Status.Text"
                      :value="Status.Value">
                      {{ Status.Text }}
                    </option>
                  </select>
                </div>
                <div class="form-group textarea">
                  <label for="">值勤內容概述<span style="color: red">*</span></label>
                  <textarea class="form-control" rows="5" placeholder="請輸入值勤內容概述" v-model="CaseInfoServiceLog.Summary"
                    data-required="1" data-required-msg="請輸入值勤內容概述"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="btn">
            <button class="add">
              巡察結果
              <div class="plus"></div>
            </button>
          </div>
          <div>
            <div class="box">
              <div class="form">
                <div class="form-group animal">
                  <label for="">已收容動物隻數_狗</label>
                  <select class="form-control" v-model="CaseInfoServiceLog.Dog"
                    style="flex-basis: calc(100% - 28px); text-align: right">
                    <option value="0">0</option>
                    <option :value="n" v-for="n in 100" :key="n">
                      {{ n }}
                    </option>
                  </select>
                  <!-- <input
                    type="number"
                    class="form-control"
                    placeholder="0"
                    v-model="CaseInfoServiceLog.Dog"
                  /> -->
                  <p>隻</p>
                </div>
                <div class="form-group animal">
                  <label for="">已收容動物隻數_哺乳</label>
                  <select class="form-control" v-model="CaseInfoServiceLog.Mammals"
                    style="flex-basis: calc(100% - 28px); text-align: right">
                    <option value="0">0</option>
                    <option :value="n" v-for="n in 100" :key="n">
                      {{ n }}
                    </option>
                  </select>
                  <!-- <input
                    type="number"
                    class="form-control"
                    placeholder="0"
                    v-model="CaseInfoServiceLog.Mammals"
                  /> -->
                  <p>隻</p>
                </div>
                <div class="form-group animal">
                  <label for="">已收容動物隻數_貓</label>
                  <select class="form-control" v-model="CaseInfoServiceLog.Cat"
                    style="flex-basis: calc(100% - 28px); text-align: right">
                    <option value="0">0</option>
                    <option :value="n" v-for="n in 100" :key="n">
                      {{ n }}
                    </option>
                  </select>
                  <!-- <input
                    type="number"
                    class="form-control"
                    placeholder="0"
                    v-model="CaseInfoServiceLog.Cat"
                  /> -->
                  <p>隻</p>
                </div>
                <div class="form-group animal">
                  <label for="">已收容動物隻數_家禽</label>
                  <select class="form-control" v-model="CaseInfoServiceLog.Poultry"
                    style="flex-basis: calc(100% - 28px); text-align: right">
                    <option value="0">0</option>
                    <option :value="n" v-for="n in 100" :key="n">
                      {{ n }}
                    </option>
                  </select>
                  <!-- <input
                    type="number"
                    class="form-control"
                    placeholder="0"
                    v-model="CaseInfoServiceLog.Poultry"
                  /> -->
                  <p>隻</p>
                </div>
                <div class="form-group animal">
                  <label for="">已收容動物隻數_鳥類</label>
                  <select class="form-control" v-model="CaseInfoServiceLog.Bird"
                    style="flex-basis: calc(100% - 28px); text-align: right">
                    <option value="0">0</option>
                    <option :value="n" v-for="n in 100" :key="n">
                      {{ n }}
                    </option>
                  </select>
                  <!-- <input
                    type="number"
                    class="form-control"
                    placeholder="0"
                    v-model="CaseInfoServiceLog.Bird"
                  /> -->
                  <p>隻</p>
                </div>
                <div class="form-group animal">
                  <label for="">已收容動物隻數_其他</label>
                  <select class="form-control" v-model="CaseInfoServiceLog.Other"
                    style="flex-basis: calc(100% - 28px); text-align: right">
                    <option value="0">0</option>
                    <option :value="n" v-for="n in 100" :key="n">
                      {{ n }}
                    </option>
                  </select>
                  <!-- <input
                    type="number"
                    class="form-control"
                    placeholder="0"
                    v-model="CaseInfoServiceLog.Other"
                  /> -->
                  <p>隻</p>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="form">
                <div class="form-group full">
                  <label for="">未能收容原因</label>
                  <div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="UncontainedReason_0"
                        @click="CaseInfoServiceLog.UncontainedReason = 0"
                        :checked="CaseInfoServiceLog.UncontainedReason == 0" />
                      <label class="form-check-label" for="UncontainedReason_0">
                        已全數收容
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="UncontainedReason_1"
                        @click="CaseInfoServiceLog.UncontainedReason = 1"
                        :checked="CaseInfoServiceLog.UncontainedReason == 1" />
                      <label class="form-check-label" for="UncontainedReason_1">
                        未發現(犬/貓)隻
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="UncontainedReason_2"
                        @click="CaseInfoServiceLog.UncontainedReason = 2"
                        :checked="CaseInfoServiceLog.UncontainedReason == 2" />
                      <label class="form-check-label" for="UncontainedReason_2">
                        聞風而逃
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="UncontainedReason_3"
                        @click="CaseInfoServiceLog.UncontainedReason = 3"
                        :checked="CaseInfoServiceLog.UncontainedReason == 3" />
                      <label class="form-check-label" for="UncontainedReason_3">
                        地形因素
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="UncontainedReason_4"
                        @click="CaseInfoServiceLog.UncontainedReason = 4"
                        :checked="CaseInfoServiceLog.UncontainedReason == 4" />
                      <label class="form-check-label" for="UncontainedReason_4">
                        逕行驅離
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="UncontainedReason_5"
                        @click="CaseInfoServiceLog.UncontainedReason = 5"
                        :checked="CaseInfoServiceLog.UncontainedReason == 5" />
                      <label class="form-check-label" for="UncontainedReason_5">
                        其他處理情形
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="box" v-show="CaseInfoServiceLog.UncontainedReason == 5">
              <div class="form">
                <div class="form-group full">
                  <label for="">其他處理情形</label>
                  <div>
                    <div class="form-check form-check-inline" style="flex-basis: calc(100% / 2)">
                      <input class="form-check-input" type="radio" id="OtherMethod_1"
                        @click="CaseInfoServiceLog.OtherMethod = 1" :checked="CaseInfoServiceLog.OtherMethod == 1" />
                      <label class="form-check-label" for="OtherMethod_1">
                        誘(犬/貓)籠已交陳情人使用
                      </label>
                    </div>
                    <div class="form-check form-check-inline" style="flex-basis: calc(100% / 2)">
                      <input class="form-check-input" type="radio" id="OtherMethod_2"
                        @click="CaseInfoServiceLog.OtherMethod = 2" :checked="CaseInfoServiceLog.OtherMethod == 2" />
                      <label class="form-check-label" for="OtherMethod_2">
                        誘(犬/貓)籠已換籠
                      </label>
                    </div>
                    <div class="form-check form-check-inline" style="flex-basis: calc(100% / 2)">
                      <input class="form-check-input" type="radio" id="OtherMethod_3"
                        @click="CaseInfoServiceLog.OtherMethod = 3" :checked="CaseInfoServiceLog.OtherMethod == 3" />
                      <label class="form-check-label" for="OtherMethod_3">
                        誘(犬/貓)籠已帶回
                      </label>
                    </div>
                    <div class="form-check form-check-inline" style="flex-basis: calc(100% / 2)">
                      <input class="form-check-input" type="radio" id="OtherMethod_4"
                        @click="CaseInfoServiceLog.OtherMethod = 4" :checked="CaseInfoServiceLog.OtherMethod == 4" />
                      <label class="form-check-label" for="OtherMethod_4">
                        送至動保處/動物之家/動物醫院
                      </label>
                    </div>
                    <div class="form-check form-check-inline" style="flex-basis: calc(100% / 2)">
                      <input class="form-check-input" type="radio" id="OtherMethod_5"
                        @click="CaseInfoServiceLog.OtherMethod = 5" :checked="CaseInfoServiceLog.OtherMethod == 5" />
                      <label class="form-check-label" for="OtherMethod_5">
                        其他
                      </label>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div class="form-group" v-show="CaseInfoServiceLog.OtherMethod == 4">
                  <label for="">送至<span style="color: red" v-if="CaseInfoServiceLog.UncontainedReason == 5 &&
                    CaseInfoServiceLog.OtherMethod == 4
                  ">*</span></label>
                  <input type="text" class="form-control" placeholder="請輸入送至何處" v-model="CaseInfoServiceLog.MoveTo"
                    :data-required="CaseInfoServiceLog.UncontainedReason == 5 &&
                      CaseInfoServiceLog.OtherMethod == 4
                      ? '1'
                      : '0'
                      " data-required-msg="請輸入送至何處" />
                </div>
                <div class="form-group" v-show="CaseInfoServiceLog.OtherMethod == 5">
                  <label for="">其他原因<span style="color: red" v-if="CaseInfoServiceLog.UncontainedReason == 5 &&
                    CaseInfoServiceLog.OtherMethod == 5
                  ">*</span></label>
                  <input type="text" class="form-control" placeholder="請輸入其他原因"
                    v-model="CaseInfoServiceLog.OtherReasons" :data-required="CaseInfoServiceLog.UncontainedReason == 5 &&
                      CaseInfoServiceLog.OtherMethod == 5
                      ? '1'
                      : '0'
                      " data-required-msg="請輸入其他原因" />
                </div>
              </div>
            </div>
          </div>
          <div class="btn">
            <button class="add">
              現場處理通知
              <div class="plus"></div>
            </button>
          </div>
          <div>
            <div class="box">
              <div class="form">
                <div class="form-group full">
                  <label for="">處理時間<span style="color: red">*</span></label>
                  <input type="datetime-local" class="form-control" v-model="CaseInfoServiceLog.MoveTime"
                    data-required="1" data-required-msg="請選擇處理時間" />
                  <div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="isTogether"
                        v-model="CaseInfoServiceLog.IsTogether" />
                      <label class="form-check-label" for="isTogether">是否會同辦理</label>
                    </div>
                  </div>
                  <div class="form-group" style="
                      padding-left: 15px;
                      margin-top: 0.5rem;
                      margin-bottom: 0.5rem;
                    " v-show="!CaseInfoServiceLog.IsTogether">
                    <input type="text" class="form-control" placeholder="請輸入未會同辦理原因"
                      v-model="CaseInfoServiceLog.NoTogetherReason" :data-required="!CaseInfoServiceLog.IsTogether ? '1' : '0'
                        " data-required-msg="請輸入未會同辦理原因" />
                  </div>
                  <div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="missedCall"
                        v-model="CaseInfoServiceLog.MissedCall" />
                      <label class="form-check-label" for="missedCall">是否未接電話</label>
                    </div>
                  </div>
                  <div class="form-group" style="
                      padding-left: 15px;
                      margin-top: 0.5rem;
                      margin-bottom: 0.5rem;
                    " v-show="CaseInfoServiceLog.MissedCall">
                    <input type="text" class="form-control" placeholder="請輸入未接電話原因"
                      v-model="CaseInfoServiceLog.MissedCallReason"
                      :data-required="CaseInfoServiceLog.MissedCall ? '1' : '0'" data-required-msg="請輸入未接電話原因" />
                  </div>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="form">
                <div class="form-group full">
                  <label for="">是否已聯繫</label>
                  <div class="basis_3" v-if="!CaseInfoServiceLog.ConnectNone">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="connectInquirer"
                        v-model="CaseInfoServiceLog.ConnectInquirer" />
                      <label class="form-check-label" for="connectInquirer">陳情人</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="connectChiefOfVillage"
                        v-model="CaseInfoServiceLog.ConnectChiefOfVillage" />
                      <label class="form-check-label" for="connectChiefOfVillage">里長</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="connectNiceGuy"
                        v-model="CaseInfoServiceLog.ConnectNiceGuy" />
                      <label class="form-check-label" for="connectNiceGuy">當地愛心團體(餵養人士)</label>
                    </div>
                  </div>
                  <div class="basis_3" v-if="!CaseInfoServiceLog.ConnectNone">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="connectVoicemail"
                        v-model="CaseInfoServiceLog.ConnectVoicemail" />
                      <label class="form-check-label" for="connectVoicemail">有留語音信箱</label>
                    </div>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="connectNone"
                      v-model="CaseInfoServiceLog.ConnectNone" />
                    <label class="form-check-label" for="connectNone">未聯繫</label>
                  </div>
                  <div class="form-group" style="
                          padding-left: 15px;
                          margin-top: 0.5rem;
                          margin-bottom: 0.5rem;
                        " v-show="CaseInfoServiceLog.ConnectNone">
                    <input type="text" class="form-control" placeholder="請輸入未聯繫備註"
                      v-model="CaseInfoServiceLog.ConnectNoneContent"
                      :data-required="CaseInfoServiceLog.ConnectNone ? '1' : '0'" data-required-msg="請輸入未聯繫備註" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btn">
            <button class="add">
              現場評估事項
              <div class="plus"></div>
            </button>
          </div>
          <div class="box">
            <div class="form">
              <div class="form-group full">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="hasNiceGuy"
                    v-model="CaseInfoServiceLog.HasNiceGuy" />
                  <label class="form-check-label" for="hasNiceGuy">有無餵養人士</label>
                </div>
                <div class="form-group" style="
                    padding-left: 15px;
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                  " v-show="CaseInfoServiceLog.HasNiceGuy">
                  <input type="text" class="form-control" placeholder="請輸入姓名" v-model="CaseInfoServiceLog.NiceGuyName"
                    :data-required="CaseInfoServiceLog.HasNiceGuy ? '1' : '0'" data-required-msg="請輸入姓名" />
                </div>
                <div class="form-group" style="
                    padding-left: 15px;
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                  " v-show="CaseInfoServiceLog.HasNiceGuy">
                  <input type="text" class="form-control" placeholder="請輸入電話" v-model="CaseInfoServiceLog.NiceGuyPhone"
                    :data-required="CaseInfoServiceLog.HasNiceGuy ? '1' : '0'" data-required-msg="請輸入電話" />
                </div>
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="isTNVR" v-model="CaseInfoServiceLog.IsTNVR" />
                  <label class="form-check-label" for="isTNVR">是否列入TNVR</label>
                </div>
                <div class="form-group" style="
                    padding-left: 15px;
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                  " v-show="CaseInfoServiceLog.IsTNVR">
                  <select class="form-control" v-model="CaseInfoServiceLog.TNVRCount"
                    :data-required="CaseInfoServiceLog.IsTNVR ? '1' : '0'" data-required-msg="請輸入TNVR隻數"
                    style="flex-basis: calc(100% - 28px); text-align: right">
                    <option value="0">0</option>
                    <option :value="n" v-for="n in 100" :key="n">
                      {{ n }}
                    </option>
                  </select>
                  <!-- <input
                    type="number"
                    class="form-control"
                    placeholder="請輸入TNVR隻數"
                    v-model="CaseInfoServiceLog.TNVRCount"
                    :data-required="CaseInfoServiceLog.IsTNVR ? '1' : '0'"
                    data-required-msg="請輸入TNVR隻數"
                  /> -->
                </div>
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="isSpecial"
                    v-model="CaseInfoServiceLog.IsSpecial" />
                  <label class="form-check-label" for="isSpecial">特殊情況</label>
                </div>
                <div class="form-group" style="
                    padding-left: 15px;
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                  " v-show="CaseInfoServiceLog.IsSpecial">
                  <!-- <select class="form-control" v-model="CaseInfoServiceLog.SpecialCount"
                    :data-required="CaseInfoServiceLog.IsSpecial ? '1' : '0'" data-required-msg="請輸入特殊情況隻數"
                    style="flex-basis: calc(100% - 28px); text-align: right">
                    <option value="0">0</option>
                    <option :value="n" v-for="n in 100" :key="n">
                      {{ n }}
                    </option>
                  </select> -->
                  <input type="text" class="form-control" placeholder="請輸入特殊情況"
                    v-model="CaseInfoServiceLog.SpecialCount" :data-required="CaseInfoServiceLog.IsSpecial ? '1' : '0'"
                    data-required-msg="請輸入特殊情況" />
                </div>
              </div>
            </div>
          </div>
          <div class="btn" v-show="CaseType.IsNeedReportInfo">
            <button class="add">
              家戶訪查清查狀態
              <div class="plus"></div>
            </button>
          </div>
          <div class="box" v-show="CaseType.IsNeedReportInfo">
            <div class="form">
              <div class="form-group full">
                <div class="basis_2">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="InventoryStatus_1"
                      @click="CaseInfoServiceLog.InventoryStatus = 1"
                      :checked="CaseInfoServiceLog.InventoryStatus == 1" />
                    <label class="form-check-label" for="InventoryStatus_1">
                      開始清查
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="InventoryStatus_2"
                      @click="CaseInfoServiceLog.InventoryStatus = 2"
                      :checked="CaseInfoServiceLog.InventoryStatus == 2" />
                    <label class="form-check-label" for="InventoryStatus_2">
                      犬隻因身體免絕育證明
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="InventoryStatus_3"
                      @click="CaseInfoServiceLog.InventoryStatus = 3"
                      :checked="CaseInfoServiceLog.InventoryStatus == 3" />
                    <label class="form-check-label" for="InventoryStatus_3">
                      無狗吠聲
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="InventoryStatus_4"
                      @click="CaseInfoServiceLog.InventoryStatus = 4"
                      :checked="CaseInfoServiceLog.InventoryStatus == 4" />
                    <label class="form-check-label" for="InventoryStatus_4">
                      無此戶
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="InventoryStatus_5"
                      @click="CaseInfoServiceLog.InventoryStatus = 5"
                      :checked="CaseInfoServiceLog.InventoryStatus == 5" />
                    <label class="form-check-label" for="InventoryStatus_5">
                      無人應門
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="InventoryStatus_6"
                      @click="CaseInfoServiceLog.InventoryStatus = 6"
                      :checked="CaseInfoServiceLog.InventoryStatus == 6" />
                    <label class="form-check-label" for="InventoryStatus_6">
                      無人居住
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="InventoryStatus_7"
                      @click="CaseInfoServiceLog.InventoryStatus = 7"
                      :checked="CaseInfoServiceLog.InventoryStatus == 7" />
                    <label class="form-check-label" for="InventoryStatus_7">
                      拒不配合調查
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="InventoryStatus_8"
                      @click="CaseInfoServiceLog.InventoryStatus = 8"
                      :checked="CaseInfoServiceLog.InventoryStatus == 8" />
                    <label class="form-check-label" for="InventoryStatus_8">
                      無飼養犬貓
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="InventoryStatus_10"
                      @click="CaseInfoServiceLog.InventoryStatus = 10"
                      :checked="CaseInfoServiceLog.InventoryStatus == 10" />
                    <label class="form-check-label" for="InventoryStatus_10">
                      無飼養犬隻
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="InventoryStatus_9"
                      @click="CaseInfoServiceLog.InventoryStatus = 9"
                      :checked="CaseInfoServiceLog.InventoryStatus == 9" />
                    <label class="form-check-label" for="InventoryStatus_9">
                      其他原因
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group animal">
                <label for="">清查犬隻總數</label>
                <select class="form-control" v-model="CaseInfoServiceLog.InventoryDog"
                  style="flex-basis: calc(100% - 28px); text-align: right">
                  <option value="0">0</option>
                  <option :value="n" v-for="n in 100" :key="n">
                    {{ n }}
                  </option>
                </select>
                <p>隻</p>
              </div>
              <div class="form-group animal">
                <label for="">已登記數</label>
                <select class="form-control" v-model="CaseInfoServiceLog.Registered"
                  style="flex-basis: calc(100% - 28px); text-align: right">
                  <option value="0">0</option>
                  <option :value="n" v-for="n in 100" :key="n">
                    {{ n }}
                  </option>
                </select>
                <p>隻</p>
              </div>
              <div class="form-group animal">
                <label for="">已絕育數</label>
                <select class="form-control" v-model="CaseInfoServiceLog.Sterilization"
                  style="flex-basis: calc(100% - 28px); text-align: right">
                  <option value="0">0</option>
                  <option :value="n" v-for="n in 100" :key="n">
                    {{ n }}
                  </option>
                </select>
                <p>隻</p>
              </div>
              <div class="form-group animal">
                <label for="">免絕育數</label>
                <select class="form-control" v-model="CaseInfoServiceLog.NoNeedSterilization"
                  style="flex-basis: calc(100% - 28px); text-align: right">
                  <option value="0">0</option>
                  <option :value="n" v-for="n in 100" :key="n">
                    {{ n }}
                  </option>
                </select>
                <p>隻</p>
              </div>
              <div class="form-group animal">
                <label for="">已注射疫苗數</label>
                <select class="form-control" v-model="CaseInfoServiceLog.Vaccination"
                  style="flex-basis: calc(100% - 28px); text-align: right">
                  <option value="0">0</option>
                  <option :value="n" v-for="n in 100" :key="n">
                    {{ n }}
                  </option>
                </select>
                <p>隻</p>
              </div>
              <div class="form-group animal">
                <label for="">母犬數</label>
                <select class="form-control" v-model="CaseInfoServiceLog.FemaleDog"
                  style="flex-basis: calc(100% - 28px); text-align: right">
                  <option value="0">0</option>
                  <option :value="n" v-for="n in 100" :key="n">
                    {{ n }}
                  </option>
                </select>
                <p>隻</p>
              </div>
              <div class="form-group">
                <label for="">編號</label>
                <input type="text" class="form-control" placeholder="請輸入編號" v-model="CaseInfoServiceLog.No" />
              </div>
            </div>
          </div>
          <div class="btn" v-show="CaseType.IsNeedReportInfo">
            <button class="add">
              遊蕩犬清查狀態
              <div class="plus"></div>
            </button>
          </div>
          <div class="box" v-show="CaseType.IsNeedReportInfo">
            <div class="form">
              <div class="form-group full">
                <label for="">是否有主人</label>
                <div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="MasterStatus_1"
                      @click="CaseInfoServiceLog.MasterStatus = 1" :checked="CaseInfoServiceLog.MasterStatus == 1" />
                    <label class="form-check-label" for="MasterStatus_1">
                      無主犬
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="MasterStatus_2"
                      @click="CaseInfoServiceLog.MasterStatus = 2" :checked="CaseInfoServiceLog.MasterStatus == 2" />
                    <label class="form-check-label" for="MasterStatus_2">
                      有主犬
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="MasterStatus_0"
                      @click="CaseInfoServiceLog.MasterStatus = 0" :checked="CaseInfoServiceLog.MasterStatus == 0" />
                    <label class="form-check-label" for="MasterStatus_0">
                      不確定
                    </label>
                  </div>
                </div>
              </div>
              <hr class="line" />
              <div class="form-group full">
                <label for="">是否絕育</label>
                <div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="SterilizationStatus_1"
                      @click="CaseInfoServiceLog.SterilizationStatus = 1"
                      :checked="CaseInfoServiceLog.SterilizationStatus == 1" />
                    <label class="form-check-label" for="SterilizationStatus_1">
                      未絕育
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="SterilizationStatus_2"
                      @click="CaseInfoServiceLog.SterilizationStatus = 2"
                      :checked="CaseInfoServiceLog.SterilizationStatus == 2" />
                    <label class="form-check-label" for="SterilizationStatus_2">
                      絕育
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="SterilizationStatus_0"
                      @click="CaseInfoServiceLog.SterilizationStatus = 0"
                      :checked="CaseInfoServiceLog.SterilizationStatus == 0" />
                    <label class="form-check-label" for="SterilizationStatus_0">
                      不確定
                    </label>
                  </div>
                </div>
              </div>
              <hr class="line" />
              <div class="form-group full">
                <label for="">處置狀態</label>
                <div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="DisposalStatus_1"
                      @click="CaseInfoServiceLog.DisposalStatus = 1"
                      :checked="CaseInfoServiceLog.DisposalStatus == 1" />
                    <label class="form-check-label" for="DisposalStatus_1">
                      紀錄
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="DisposalStatus_2"
                      @click="CaseInfoServiceLog.DisposalStatus = 2"
                      :checked="CaseInfoServiceLog.DisposalStatus == 2" />
                    <label class="form-check-label" for="DisposalStatus_2">
                      抓捕
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="DisposalStatus_3"
                      @click="CaseInfoServiceLog.DisposalStatus = 3"
                      :checked="CaseInfoServiceLog.DisposalStatus == 3" />
                    <label class="form-check-label" for="DisposalStatus_3">
                      回置
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="DisposalStatus_4"
                      @click="CaseInfoServiceLog.DisposalStatus = 4"
                      :checked="CaseInfoServiceLog.DisposalStatus == 4" />
                    <label class="form-check-label" for="DisposalStatus_4">
                      死亡
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="DisposalStatus_5"
                      @click="CaseInfoServiceLog.DisposalStatus = 5"
                      :checked="CaseInfoServiceLog.DisposalStatus == 5" />
                    <label class="form-check-label" for="DisposalStatus_5">
                      不見了
                    </label>
                  </div>
                </div>
              </div>
              <hr class="line" />
              <div class="form-group full">
                <label for="">花色(可複選)</label>
                <div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="Color_Black"
                      v-model="CaseInfoServiceLog.Color_Black" />
                    <label class="form-check-label" for="Color_Black">黑</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="Color_White"
                      v-model="CaseInfoServiceLog.Color_White" />
                    <label class="form-check-label" for="Color_White">白</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="Color_Yellow"
                      v-model="CaseInfoServiceLog.Color_Yellow" />
                    <label class="form-check-label" for="Color_Yellow">黃</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="Color_Spot"
                      v-model="CaseInfoServiceLog.Color_Spot" />
                    <label class="form-check-label" for="Color_Spot">花</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="Color_BlackAndWhite"
                      v-model="CaseInfoServiceLog.Color_BlackAndWhite" />
                    <label class="form-check-label" for="Color_BlackAndWhite">黑白</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="Color_BlackAndYellow"
                      v-model="CaseInfoServiceLog.Color_BlackAndYellow" />
                    <label class="form-check-label" for="Color_BlackAndYellow">黑黃</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="Color_WhiteAndYellow"
                      v-model="CaseInfoServiceLog.Color_WhiteAndYellow" />
                    <label class="form-check-label" for="Color_WhiteAndYellow">白黃</label>
                  </div>
                </div>
              </div>
              <hr />
              <div class="form-group">
                <label for="">種類</label>
                <input type="text" class="form-control" placeholder="請輸入種類" v-model="CaseInfoServiceLog.Kind" />
              </div>
              <div class="form-group">
                <label for="">寵物登記</label>
                <input type="text" class="form-control" placeholder="請輸入寵物登記"
                  v-model="CaseInfoServiceLog.PetRegister" />
              </div>
            </div>
          </div>
          <div class="btn" v-show="CaseType.IsNeedReportInfo">
            <button class="add">
              餵食者清查狀況
              <div class="plus"></div>
            </button>
          </div>
          <div class="box" v-show="CaseType.IsNeedReportInfo">
            <div class="form">
              <div class="form-group full">
                <label for="">處理類型</label>
                <div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="FeederStatus_1"
                      @click="CaseInfoServiceLog.FeederStatus = 1" :checked="CaseInfoServiceLog.FeederStatus == 1" />
                    <label class="form-check-label" for="FeederStatus_1">
                      紀錄
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="FeederStatus_2"
                      @click="CaseInfoServiceLog.FeederStatus = 2" :checked="CaseInfoServiceLog.FeederStatus == 2" />
                    <label class="form-check-label" for="FeederStatus_2">
                      勸導
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group textarea">
                <label for="">地點說明</label>
                <textarea class="form-control" rows="5" placeholder="請輸入地點說明"
                  v-model="CaseInfoServiceLog.FeederContent"></textarea>
              </div>
            </div>
          </div>
          <div class="btn" v-show="CaseType.IsNeedReportInfo">
            <button class="add">
              野生動物資訊
              <div class="plus"></div>
            </button>
          </div>
          <div class="box" v-show="CaseType.IsNeedReportInfo">
            <div class="form">
              <div class="form-group">
                <label for="">型態</label>
                <input type="text" class="form-control" placeholder="請輸入型態" v-model="CaseInfoServiceLog.Type" />
              </div>
              <div class="form-group">
                <label for="">備註</label>
                <input type="text" class="form-control" placeholder="請輸入備註" v-model="CaseInfoServiceLog.Remark" />
              </div>
            </div>
          </div>
          <div class="btn_box">
            <button @click="goCheckPage(true)">確認輸入資料</button>
            <router-link :to="{ path: '/CaseServiceList', query: { CIId: CaseInfo.CIId } }" tag="button"
              style="width: 160px;">返回勤務登記列表</router-link>
          </div>
        </div>
      </section>
      <!-- 內容 -->
      <section class="main case_registration case_registration_form" v-if="CaseInfoServiceLog" v-show="CheckPage">
        <div class="container small">
          <div class="title">
            <h1>勤務登記表</h1>
          </div>
          <div class="btn">
            <button class="add">值勤內容</button>
          </div>
          <div>
            <div class="box">
              <div class="form">
                <div class="form-group">
                  <label for="">出勤日期</label>
                  <p>
                    {{
                      CaseInfoServiceLog.ServiceTime
                      | timeString("YYYY-MM-DD HH:mm")
                    }}
                  </p>
                </div>
                <div class="form-group">
                  <label for="">狀態</label>
                  <p v-if="CaseInfoServiceLog.Status == 1">處理中</p>
                  <p v-if="CaseInfoServiceLog.Status == 2">處理完成</p>
                </div>
                <div class="form-group textarea">
                  <label for="">值勤內容概述</label>
                  <p>{{ CaseInfoServiceLog.Summary }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="btn">
            <button class="add">巡察結果</button>
          </div>
          <div>
            <div class="box">
              <div class="form">
                <div class="form-group animal" v-if="CaseInfoServiceLog.Dog > 0">
                  <label for="">已收容動物隻數_狗</label>
                  <p>{{ CaseInfoServiceLog.Dog }}</p>
                  <p>隻</p>
                </div>
                <div class="form-group animal" v-if="CaseInfoServiceLog.Mammals > 0">
                  <label for="">已收容動物隻數_哺乳</label>
                  <p>{{ CaseInfoServiceLog.Mammals }}</p>
                  <p>隻</p>
                </div>
                <div class="form-group animal" v-if="CaseInfoServiceLog.Cat > 0">
                  <label for="">已收容動物隻數_貓</label>
                  <p>{{ CaseInfoServiceLog.Cat }}</p>
                  <p>隻</p>
                </div>
                <div class="form-group animal" v-if="CaseInfoServiceLog.Poultry > 0">
                  <label for="">已收容動物隻數_家禽</label>
                  <p>{{ CaseInfoServiceLog.Poultry }}</p>
                  <p>隻</p>
                </div>
                <div class="form-group animal" v-if="CaseInfoServiceLog.Bird > 0">
                  <label for="">已收容動物隻數_鳥類</label>
                  <p>{{ CaseInfoServiceLog.Bird }}</p>
                  <p>隻</p>
                </div>
                <div class="form-group animal" v-if="CaseInfoServiceLog.Other > 0">
                  <label for="">已收容動物隻數_其他</label>
                  <p>{{ CaseInfoServiceLog.Other }}</p>
                  <p>隻</p>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="form">
                <div class="form-group full reason">
                  <label for="">未能收容原因</label>
                  <div>
                    <p v-if="CaseInfoServiceLog.UncontainedReason == 0">
                      已全數收容
                    </p>
                    <p v-if="CaseInfoServiceLog.UncontainedReason == 1">
                      未發現(犬/貓)隻
                    </p>
                    <p v-if="CaseInfoServiceLog.UncontainedReason == 2">
                      聞風逃離
                    </p>
                    <p v-if="CaseInfoServiceLog.UncontainedReason == 3">
                      地形因素
                    </p>
                    <p v-if="CaseInfoServiceLog.UncontainedReason == 4">
                      逕行驅離
                    </p>
                    <p v-if="CaseInfoServiceLog.UncontainedReason == 5">
                      其他處理情形
                    </p>
                  </div>
                </div>
                <div class="form-group full reason" v-if="CaseInfoServiceLog.UncontainedReason == 5">
                  <label for="">其他處理情形</label>
                  <div>
                    <p v-if="CaseInfoServiceLog.OtherMethod == 1">
                      誘(犬/貓)籠已交陳情人使用
                    </p>
                    <p v-if="CaseInfoServiceLog.OtherMethod == 2">
                      誘(犬/貓)籠已換籠
                    </p>
                    <p v-if="CaseInfoServiceLog.OtherMethod == 3">
                      誘(犬/貓)籠已帶回
                    </p>
                    <p v-if="CaseInfoServiceLog.OtherMethod == 4">
                      送至動保處/動物之家/動物醫院
                    </p>
                    <p v-if="CaseInfoServiceLog.OtherMethod == 5">
                      其他
                    </p>
                  </div>
                </div>
                <div class="form-group full reason" v-if="CaseInfoServiceLog.UncontainedReason == 5 &&
                  CaseInfoServiceLog.OtherMethod == 4
                ">
                  <label for="">送至</label>
                  <div>
                    <p>{{ CaseInfoServiceLog.MoveTo }}</p>
                  </div>
                </div>
                <div class="form-group full reason" v-if="CaseInfoServiceLog.UncontainedReason == 5 &&
                  CaseInfoServiceLog.OtherMethod == 5
                ">
                  <label for="">其他原因</label>
                  <div>
                    <p>{{ CaseInfoServiceLog.OtherReasons }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btn">
            <button class="add">現場處理通知</button>
          </div>
          <div>
            <div class="box">
              <div class="form">
                <div class="form-group full">
                  <label for="">是否已聯繫</label>
                  <p v-if="!CaseInfoServiceLog.ConnectNone && CaseInfoServiceLog.ConnectInquirer">陳情人</p>
                  <p v-if="!CaseInfoServiceLog.ConnectNone && CaseInfoServiceLog.ConnectChiefOfVillage">里長</p>
                  <p v-if="!CaseInfoServiceLog.ConnectNone && CaseInfoServiceLog.ConnectNiceGuy">
                    當地愛心團體(餵養人士)
                  </p>
                  <p v-if="!CaseInfoServiceLog.ConnectNone && CaseInfoServiceLog.ConnectVoicemail">有留語音信箱</p>
                  <p v-if="CaseInfoServiceLog.ConnectNone">未聯繫: {{ CaseInfoServiceLog.ConnectNoneContent }}</p>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="form">
                <div class="form-group full">
                  <label for="">處理時間</label>
                  <p v-if="CaseInfoServiceLog.MoveTime">
                    {{
                      CaseInfoServiceLog.MoveTime
                      | timeString("YYYY-MM-DD HH:mm")
                    }}
                  </p>
                  <div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" :checked="CaseInfoServiceLog.IsTogether"
                        disabled />
                      <label class="form-check-label">是否會同辦理</label>
                    </div>
                    <div class="form-group full reason" style="
                        padding-left: 20px;
                        margin-top: 0.5rem;
                        margin-bottom: 0.5rem;
                      " v-if="!CaseInfoServiceLog.IsTogether">
                      <label for="">未會同辦理原因</label>
                      <div>
                        <p>{{ CaseInfoServiceLog.NoTogetherReason }}</p>
                      </div>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" :checked="CaseInfoServiceLog.MissedCall"
                        disabled />
                      <label class="form-check-label">是否未接電話</label>
                    </div>
                    <div class="form-group full reason" style="
                        padding-left: 20px;
                        margin-top: 0.5rem;
                        margin-bottom: 0.5rem;
                      " v-if="CaseInfoServiceLog.MissedCall">
                      <label for="">未接電話原因</label>
                      <div>
                        <p>{{ CaseInfoServiceLog.MissedCallReason }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btn" v-show="CaseType.IsNeedReportInfo">
            <button class="add">現場評估事項</button>
          </div>
          <div class="box" v-show="CaseType.IsNeedReportInfo">
            <div class="form">
              <div class="form-group full">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" :checked="CaseInfoServiceLog.HasNiceGuy" disabled />
                  <label class="form-check-label">有無餵養人士</label>
                </div>
                <div class="form-group full reason" style="
                    padding-left: 20px;
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                  " v-if="CaseInfoServiceLog.HasNiceGuy">
                  <label for="">姓名</label>
                  <div>
                    <p>{{ CaseInfoServiceLog.NiceGuyName }}</p>
                  </div>
                </div>
                <div class="form-group full reason" style="
                    padding-left: 20px;
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                  " v-if="CaseInfoServiceLog.HasNiceGuy">
                  <label for="">電話</label>
                  <div>
                    <p>{{ CaseInfoServiceLog.NiceGuyPhone }}</p>
                  </div>
                </div>
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" :checked="CaseInfoServiceLog.IsTNVR" disabled />
                  <label class="form-check-label">是否列入TNVR</label>
                </div>
                <div class="form-group full reason" style="
                    padding-left: 20px;
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                  " v-if="CaseInfoServiceLog.IsTNVR">
                  <label for="">TNVR隻數</label>
                  <div>
                    <p>{{ CaseInfoServiceLog.TNVRCount }}</p>
                  </div>
                </div>
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" :checked="CaseInfoServiceLog.IsSpecial" disabled />
                  <label class="form-check-label">特殊情況</label>
                </div>
                <div class="form-group full reason" style="
                    padding-left: 20px;
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                  " v-if="CaseInfoServiceLog.IsSpecial">
                  <label for="">特殊情況</label>
                  <div>
                    <p>{{ CaseInfoServiceLog.SpecialCount }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btn" v-show="CaseType.IsNeedReportInfo">
            <button class="add">家戶訪查清查狀態</button>
          </div>
          <div class="box" v-show="CaseType.IsNeedReportInfo">
            <div class="form">
              <div class="form-group full">
                <p v-if="CaseInfoServiceLog.InventoryStatus == 1">開始清查</p>
                <p v-if="CaseInfoServiceLog.InventoryStatus == 2">
                  犬隻因身體免絕育證明
                </p>
                <p v-if="CaseInfoServiceLog.InventoryStatus == 3">無狗吠聲</p>
                <p v-if="CaseInfoServiceLog.InventoryStatus == 4">無此戶</p>
                <p v-if="CaseInfoServiceLog.InventoryStatus == 5">無人應門</p>
                <p v-if="CaseInfoServiceLog.InventoryStatus == 6">無人居住</p>
                <p v-if="CaseInfoServiceLog.InventoryStatus == 7">
                  拒不配合調查
                </p>
                <p v-if="CaseInfoServiceLog.InventoryStatus == 8">無飼養犬貓</p>
                <p v-if="CaseInfoServiceLog.InventoryStatus == 10">無飼養犬隻</p>
                <p v-if="CaseInfoServiceLog.InventoryStatus == 9">其他原因</p>
              </div>
            </div>
          </div>
          <div class="btn" v-show="CaseType.IsNeedReportInfo">
            <button class="add">遊蕩犬清查狀態</button>
          </div>
          <div class="box" v-show="CaseType.IsNeedReportInfo">
            <div class="form">
              <div class="form-group full">
                <label for="">是否有主人</label>
                <p v-if="CaseInfoServiceLog.MasterStatus == 1">無主犬</p>
                <p v-if="CaseInfoServiceLog.MasterStatus == 2">有主犬</p>
                <p v-if="CaseInfoServiceLog.MasterStatus == 0">不確定</p>
              </div>
              <div class="form-group full">
                <label for="">是否絕育</label>
                <p v-if="CaseInfoServiceLog.SterilizationStatus == 1">未絕育</p>
                <p v-if="CaseInfoServiceLog.SterilizationStatus == 2">絕育</p>
                <p v-if="CaseInfoServiceLog.SterilizationStatus == 0">不確定</p>
              </div>
              <div class="form-group full">
                <label for="">處置狀態</label>
                <p v-if="CaseInfoServiceLog.DisposalStatus == 1">紀錄</p>
                <p v-if="CaseInfoServiceLog.DisposalStatus == 2">抓捕</p>
                <p v-if="CaseInfoServiceLog.DisposalStatus == 3">回置</p>
                <p v-if="CaseInfoServiceLog.DisposalStatus == 4">死亡</p>
                <p v-if="CaseInfoServiceLog.DisposalStatus == 5">不見了</p>
              </div>
              <div class="form-group full">
                <label for="">花色(可複選)</label>
                <p v-if="CaseInfoServiceLog.Color_Black">黑</p>
                <p v-if="CaseInfoServiceLog.Color_White">白</p>
                <p v-if="CaseInfoServiceLog.Color_Yellow">黃</p>
                <p v-if="CaseInfoServiceLog.Color_Spot">花</p>
                <p v-if="CaseInfoServiceLog.Color_BlackAndWhite">黑白</p>
                <p v-if="CaseInfoServiceLog.Color_BlackAndYellow">黑黃</p>
                <p v-if="CaseInfoServiceLog.Color_WhiteAndYellow">白黃</p>
              </div>
            </div>
          </div>
          <div class="btn" v-show="CaseType.IsNeedReportInfo">
            <button class="add">餵食者清查狀況</button>
          </div>
          <div class="box" v-show="CaseType.IsNeedReportInfo">
            <div class="form">
              <div class="form-group full">
                <label for="">處理類型</label>
                <p v-if="CaseInfoServiceLog.FeederStatus == 1">紀錄</p>
                <p v-if="CaseInfoServiceLog.FeederStatus == 2">勸導</p>
              </div>
              <div class="form-group textarea" v-if="CaseInfoServiceLog.FeederContent">
                <label for="">地點說明</label>
                <p>{{ CaseInfoServiceLog.FeederContent }}</p>
              </div>
            </div>
          </div>
          <div class="btn">
            <button class="add">電子簽名</button>
          </div>
          <div class="box">
            <!-- 0915 簽名圖片 -->
            <img id="imgSign" class="imported" />
            <div class="sign_box btn_box">
              <button type="button" class="" @click="signShow()">簽　名</button>
            </div>
            <div class="sign_modal">
              <div class="box">
                <div class="bg">
                  <h3 class="modal-title" id="exampleModalLabel">電子簽名</h3>
                  <button type="button" class="close" @click="signClose()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="main">
                  <p>請於此處進行電子簽名</p>
                  <div id="signature"></div>
                </div>
                <div class="btn_box">
                  <button type="button" id="clear" @click="signClear()">
                    清除
                  </button>
                  <button class="send" id="save" @click="signSave()">
                    儲存
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="box" style="text-align: center">
            <div class="sign_box btn_box">
              <button
                type="button"
                class=""
                data-toggle="modal"
                data-target="#signModal"
              >
                簽　名
              </button>
            </div>
            <img id="imgSign" src="" alt="" style="width: 100%; height: auto;" />
          </div> -->
          <div class="btn_box">
            <button @click="save(1)">儲存</button>
            <button @click="saveCheck()">處理完成</button>
            <button @click="goCheckPage(false)">返回</button>
          </div>
        </div>
      </section>
    </div>
    <!-- Modal -->
    <!-- <div
      class="modal fade case"
      id="signModal"
      tabindex="-1"
      aria-labelledby="signModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-body">
            <div class="bg">
              <h3 class="modal-title" id="signModalLabel">電子簽名</h3>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              id="signPaintBlock"
              style="width: 100%; height: 200px; border: solid 1px black"
            >
              <canvas id="signPaint"></canvas>
            </div>
            <div class="btn_box">
              <button id="export" class="send" data-dismiss="modal">
                確認
              </button>
              <button id="reset">清除</button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <Footer />
  </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";
import AES from "@/utils/aes.js";
import VueCookies from "vue-cookies";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export default {
  name: "CaseService",
  components: {
    Head,
    Footer,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      Member: null,

      StatusList: null,
      CaseInfo: null,
      CaseType: null,
      CaseInfoServiceLog: null,

      CheckPage: false,

      dropzoneOptions: {
        url:
          this.$store.state.src +
          this.$store.state.subDirectory +
          "/File/DZUploadCaseFront",
        init: function () {
          this.on("sending", function (file, xhr, formData) {
            formData.append("token", $cookies.get("acm_login_token"));
          });
        },
        paramName: "file",
        maxFilesize: 10,
        addRemoveLinks: true,
        acceptedFiles:
          ".png,.jpg,.jpeg,.bmp,.gif,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.odt,.ods,.odp,.pdf,.7z,.zip,.rar,.mp4,.webp,.csv",
        accept: function (file, done) {
          done();
        },
        timeout: 300000,
        autoProcessQueue: true,
        parallelUploads: 1,
        dictFileTooBig: "錯誤: 這個檔案太大了",
        dictInvalidFileType: "錯誤: 不支援此檔案格式",
        dictResponseError: "伺服器發生錯誤({{statusCode}}), 上傳失敗",
        dictCancelUpload: "取消上傳",
        dictUploadCanceled: "已取消上傳",
        dictCancelUploadConfirmation: "是否確認取消上傳?",
        dictRemoveFile: "取消",
        dictRemoveFileConfirmation: null,
        dictMaxFilesExceeded: "同時最多僅可上傳{{maxFiles}}個檔案",

        // url: "https://httpbin.org/post",
        // thumbnailWidth: 150,
        // maxFilesize: 0.5,
        // headers: { "My-Awesome-Header": "header value" },

        jSignatureInit: false,
      },

      Attachments: [],
      InspectionForms: [],
    };
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src +
        this.$store.state.subDirectory +
        "/Html/CaseService",
        {
          params: {
            CIId: this.$route.query.CIId,
            CISLId: this.$route.query.CISLId,
            token: $cookies.get("acm_login_token"),
          },
        }
      )
      .then((response) => {
        var CaseServiceData = response.data;
        if (CaseServiceData.error) {
          Swal.fire({
            title: "錯誤",
            text: CaseServiceData.error,
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
            willClose: () => {
              if (CaseServiceData.token_error) {
                $cookies.remove("acm_login_token");
                this.$router.push({
                  name: "Login",
                });
              } else if (CaseServiceData.goback) {
                this.$router.push({
                  name: "CaseList",
                });
              }
            },
          });
        } else {
          this.StatusList = CaseServiceData.StatusList;
          this.CaseInfo = CaseServiceData.CaseInfo;
          this.CaseType = CaseServiceData.CaseType;
          this.CaseInfoServiceLog = CaseServiceData.CaseInfoServiceLog;

          if (this.CaseInfoServiceLog.Attachment) {
            this.Attachments = JSON.parse(this.CaseInfoServiceLog.Attachment);
          }

          if (this.CaseInfoServiceLog.InspectionForm) {
            this.InspectionForms = JSON.parse(this.CaseInfoServiceLog.InspectionForm);
          }

          if (this.CaseInfoServiceLog.ServiceTime) {
            this.CaseInfoServiceLog.ServiceTime = this.convertDatetimeString(
              this.CaseInfoServiceLog.ServiceTime
            );
          }

          if (this.CaseInfoServiceLog.MoveTime) {
            this.CaseInfoServiceLog.MoveTime = this.convertDatetimeString(
              this.CaseInfoServiceLog.MoveTime
            );
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    // $("#signModal").on("shown.bs.modal", function (event) {
    //   var w = $("#signPaintBlock").width();
    //   var h = $("#signPaintBlock").height();
    //   //console.log(w + ", " + h)
    //   // init canvas element
    //   var canvas = document.getElementById("signPaint");
    //   canvas.width = w;
    //   canvas.height = h;
    //   var ctx = canvas.getContext("2d");
    //   // 抗鋸齒
    //   // ref: https://www.zhihu.com/question/37698502
    //   let width = canvas.width,
    //     height = canvas.height;
    //   if (window.devicePixelRatio) {
    //     canvas.style.width = width + "px";
    //     canvas.style.height = height + "px";
    //     canvas.height = height * window.devicePixelRatio;
    //     canvas.width = width * window.devicePixelRatio;
    //     ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
    //   }
    //   // mouse
    //   function getMousePos(canvas, evt) {
    //     var rect = canvas.getBoundingClientRect();
    //     return {
    //       x: evt.clientX - rect.left,
    //       y: evt.clientY - rect.top,
    //     };
    //   }
    //   function mouseMove(evt) {
    //     var mousePos = getMousePos(canvas, evt);
    //     ctx.lineCap = "round";
    //     ctx.lineWidth = 2;
    //     ctx.lineJoin = "round";
    //     ctx.shadowBlur = 1; // 邊緣模糊，防止直線邊緣出現鋸齒
    //     ctx.shadowColor = "black"; // 邊緣顏色
    //     ctx.lineTo(mousePos.x, mousePos.y);
    //     ctx.stroke();
    //   }
    //   canvas.addEventListener("mousedown", function (evt) {
    //     var mousePos = getMousePos(canvas, evt);
    //     ctx.beginPath();
    //     ctx.moveTo(mousePos.x, mousePos.y);
    //     evt.preventDefault();
    //     canvas.addEventListener("mousemove", mouseMove, false);
    //   });
    //   canvas.addEventListener(
    //     "mouseup",
    //     function () {
    //       canvas.removeEventListener("mousemove", mouseMove, false);
    //     },
    //     false
    //   );
    //   // touch
    //   function getTouchPos(canvas, evt) {
    //     var rect = canvas.getBoundingClientRect();
    //     return {
    //       x: evt.touches[0].clientX - rect.left,
    //       y: evt.touches[0].clientY - rect.top,
    //     };
    //   }
    //   function touchMove(evt) {
    //     // console.log("touchmove")
    //     var touchPos = getTouchPos(canvas, evt);
    //     // console.log(touchPos.x, touchPos.y)
    //     ctx.lineWidth = 2;
    //     ctx.lineCap = "round"; // 繪制圓形的結束線帽
    //     ctx.lineJoin = "round"; // 兩條線條交匯時，建立圓形邊角
    //     ctx.shadowBlur = 1; // 邊緣模糊，防止直線邊緣出現鋸齒
    //     ctx.shadowColor = "black"; // 邊緣顏色
    //     ctx.lineTo(touchPos.x, touchPos.y);
    //     ctx.stroke();
    //   }
    //   canvas.addEventListener("touchstart", function (evt) {
    //     // console.log('touchstart')
    //     // console.log(evt)
    //     var touchPos = getTouchPos(canvas, evt);
    //     ctx.beginPath(touchPos.x, touchPos.y);
    //     ctx.moveTo(touchPos.x, touchPos.y);
    //     evt.preventDefault();
    //     canvas.addEventListener("touchmove", touchMove, false);
    //   });
    //   canvas.addEventListener(
    //     "touchend",
    //     function () {
    //       // console.log("touchend")
    //       canvas.removeEventListener("touchmove", touchMove, false);
    //     },
    //     false
    //   );
    //   // clear
    //   document.getElementById("reset").addEventListener(
    //     "click",
    //     function () {
    //       // console.log("reset")
    //       ctx.clearRect(0, 0, canvas.width, canvas.height);
    //     },
    //     false
    //   );
    //   // convertToImage
    //   document.getElementById("export").addEventListener(
    //     "click",
    //     function () {
    //       var image = canvas.toDataURL("image/png");
    //       $("#imgSign").attr("src", image);
    //     },
    //     false
    //   );
    // });
  },
  updated: function () {
    $(".case_registration.main .btn button").click(function () {
      $(this).find(".plus").toggleClass("add");
      $(this).parent().next().slideToggle();
    });
  },
  methods: {
    saveCheck() {
      let that = this;
      Swal.fire({
        title: "請再次確認",
        text: "處理完成後的案件將無法再次進行勤務登記, 是否確定?",
        heightAuto: false,
        showCancelButton: true,
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (!result.isConfirmed) {
          return;
        } else {
          that.save(2)
        }
      });
    },
    save(Status) {
      // 設定狀態
      this.CaseInfoServiceLog.Status = Status;
      // 附加檔案
      var files = this.$refs.dropzone_files.getAcceptedFiles();
      var attachment = new Array();
      $.each(files, function (index, file) {
        var jObj = new Object();
        jObj.Name = file.response.Name;
        jObj.Url = file.response.Url;
        attachment = attachment.concat(jObj);
      });
      // 現場查核表單
      var files_2 = this.$refs.dropzone_files_2.getAcceptedFiles();
      var inspectionForm = new Array();
      $.each(files_2, function (index, file) {
        var jObj = new Object();
        jObj.Name = file.response.Name;
        jObj.Url = file.response.Url;
        inspectionForm = inspectionForm.concat(jObj);
      });
      // 取得簽名
      var imgSign = $("#imgSign").attr("src");
      if (imgSign) {
        imgSign = imgSign.replace(/\"/gi, "");
      }
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/CaseService",
        type: "post",
        data: {
          CISLId: that.CaseInfoServiceLog.CISLId,
          CIId: that.CaseInfoServiceLog.CIId,
          Attachment: JSON.stringify(attachment),
          InspectionForm: JSON.stringify(inspectionForm),
          ServiceTime: that.CaseInfoServiceLog.ServiceTime,
          Summary: that.CaseInfoServiceLog.Summary,
          Status: that.CaseInfoServiceLog.Status,
          Dog: that.CaseInfoServiceLog.Dog,
          Cat: that.CaseInfoServiceLog.Cat,
          Bird: that.CaseInfoServiceLog.Bird,
          Mammals: that.CaseInfoServiceLog.Mammals,
          Poultry: that.CaseInfoServiceLog.Poultry,
          Other: that.CaseInfoServiceLog.Other,
          UncontainedReason: that.CaseInfoServiceLog.UncontainedReason,
          OtherMethod: that.CaseInfoServiceLog.OtherMethod,
          MoveTo:
            that.CaseInfoServiceLog.MoveTo == null
              ? ""
              : that.CaseInfoServiceLog.MoveTo,
          OtherReasons:
            that.CaseInfoServiceLog.OtherReasons == null
              ? ""
              : that.CaseInfoServiceLog.OtherReasons,
          ConnectInquirer: that.CaseInfoServiceLog.ConnectInquirer,
          ConnectChiefOfVillage: that.CaseInfoServiceLog.ConnectChiefOfVillage,
          ConnectNiceGuy: that.CaseInfoServiceLog.ConnectNiceGuy,
          ConnectVoicemail: that.CaseInfoServiceLog.ConnectVoicemail,
          ConnectNone: that.CaseInfoServiceLog.ConnectNone,
          ConnectNoneContent: that.CaseInfoServiceLog.ConnectNoneContent,
          MoveTime: that.CaseInfoServiceLog.MoveTime,
          IsTogether: that.CaseInfoServiceLog.IsTogether,
          NoTogetherReason:
            that.CaseInfoServiceLog.NoTogetherReason == null
              ? ""
              : that.CaseInfoServiceLog.NoTogetherReason,
          MissedCall: that.CaseInfoServiceLog.MissedCall,
          MissedCallReason:
            that.CaseInfoServiceLog.MissedCallReason == null
              ? ""
              : that.CaseInfoServiceLog.MissedCallReason,
          HasNiceGuy: that.CaseInfoServiceLog.HasNiceGuy,
          NiceGuyName:
            that.CaseInfoServiceLog.NiceGuyName == null
              ? ""
              : that.CaseInfoServiceLog.NiceGuyName,
          NiceGuyPhone:
            that.CaseInfoServiceLog.NiceGuyPhone == null
              ? ""
              : that.CaseInfoServiceLog.NiceGuyPhone,
          IsTNVR: that.CaseInfoServiceLog.IsTNVR,
          TNVRCount: that.CaseInfoServiceLog.TNVRCount,
          IsSpecial: that.CaseInfoServiceLog.IsSpecial,
          SpecialCount: that.CaseInfoServiceLog.SpecialCount,
          InventoryStatus: that.CaseInfoServiceLog.InventoryStatus,
          MasterStatus: that.CaseInfoServiceLog.MasterStatus,
          SterilizationStatus: that.CaseInfoServiceLog.SterilizationStatus,
          DisposalStatus: that.CaseInfoServiceLog.DisposalStatus,
          Color_Black: that.CaseInfoServiceLog.Color_Black,
          Color_White: that.CaseInfoServiceLog.Color_White,
          Color_Yellow: that.CaseInfoServiceLog.Color_Yellow,
          Color_Spot: that.CaseInfoServiceLog.Color_Spot,
          Color_BlackAndWhite: that.CaseInfoServiceLog.Color_BlackAndWhite,
          Color_BlackAndYellow: that.CaseInfoServiceLog.Color_BlackAndYellow,
          Color_WhiteAndYellow: that.CaseInfoServiceLog.Color_WhiteAndYellow,
          FeederStatus: that.CaseInfoServiceLog.FeederStatus,
          FeederContent:
            that.CaseInfoServiceLog.FeederContent == null
              ? ""
              : that.CaseInfoServiceLog.FeederContent,
          Sign: imgSign,
          InventoryDog: that.CaseInfoServiceLog.InventoryDog,
          Registered: that.CaseInfoServiceLog.Registered,
          Sterilization: that.CaseInfoServiceLog.Sterilization,
          NoNeedSterilization: that.CaseInfoServiceLog.NoNeedSterilization,
          Vaccination: that.CaseInfoServiceLog.Vaccination,
          FemaleDog: that.CaseInfoServiceLog.FemaleDog,
          No:
            that.CaseInfoServiceLog.No == null
              ? ""
              : that.CaseInfoServiceLog.No,
          Kind:
            that.CaseInfoServiceLog.Kind == null
              ? ""
              : that.CaseInfoServiceLog.Kind,
          PetRegister:
            that.CaseInfoServiceLog.PetRegister == null
              ? ""
              : that.CaseInfoServiceLog.PetRegister,
          Type:
            that.CaseInfoServiceLog.Type == null
              ? ""
              : that.CaseInfoServiceLog.Type,
          Remark:
            that.CaseInfoServiceLog.Remark == null
              ? ""
              : that.CaseInfoServiceLog.Remark,
          token: $cookies.get("acm_login_token"),
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            Swal.fire({
              title: "儲存完成",
              icon: "success",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              timer: 1500,
              willClose: () => {
                that.$router.push({
                  name: "CaseServiceList",
                  query: { CIId: that.CaseInfo.CIId },
                });
              },
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
              willClose: () => {
                if (res.token_error) {
                  $cookies.remove("acm_login_token");
                  that.$router.push({
                    name: "Login",
                  });
                }
              },
            });
          }
        },
      });
    },
    vdropzone_success(file, response) {
      file.response = response;
    },
    vdropzone_error(file, message, xhr) {
      if (xhr.status == 500) {
        $(file.previewElement)
          .find(".dz-error-message")
          .text("錯誤: 伺服器發生錯誤");
      } else if (xhr.status == 501) {
        $(file.previewElement)
          .find(".dz-error-message")
          .text("錯誤: 可用空間不足");
      } else {
        $(file.previewElement).find(".dz-error-message").text("錯誤: 上傳失敗");
      }
    },
    goCheckPage(status) {
      if (status) {
        var hasError = false;
        $("[data-required='1']").each(function () {
          var input = $(this);
          if (!input.val() || (input.is("select") && input.val() == "0")) {
            Swal.fire({
              title: "錯誤",
              text: input.data("required-msg"),
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
            hasError = true;
            return false;
          }
        });
        if (
          this.CaseInfoServiceLog.UncontainedReason == 0 &&
          this.CaseInfoServiceLog.Dog +
          this.CaseInfoServiceLog.Mammals +
          this.CaseInfoServiceLog.Cat +
          this.CaseInfoServiceLog.Poultry +
          this.CaseInfoServiceLog.Bird +
          this.CaseInfoServiceLog.Other ==
          0
        ) {
          Swal.fire({
            title: "錯誤",
            text: "請填寫已收容動物隻數",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        }
        if (hasError) {
          return;
        }
      }
      this.CheckPage = status;
      $("html, body").animate({ scrollTop: 0 }, 500);
    },
    convertDatetimeString(time) {
      var date = new Date(
        time.replace(
          /^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,
          "$4:$5:$6 $2/$3/$1"
        )
      );
      return moment(date).format("YYYY-MM-DDTHH:mm");
    },
    signClear() {
      $("#signature").jSignature("reset");
    },
    signSave() {
      $(".imported").attr("src", $("#signature").jSignature("getData"));
      $(".main .box .sign_box button").text("重新簽名");
      $(".sign_modal").removeClass("add");
      $("body").removeClass("add");
    },
    signShow() {
      $(".sign_modal").addClass("add");
      $("body").addClass("add");
      if (!this.jSignatureInit) {
        $("#signature").jSignature({
          width: "100%",
          height: "200px",
          color: "#000",
          "background-color": "transparent",
        });
        this.jSignatureInit = true;
      } else {
        $("#signature").jSignature("reset");
      }
    },
    signClose() {
      $(".sign_modal").removeClass("add");
      $("body").removeClass("add");
    },
    signSend() {
      $(".sign_modal").removeClass("add");
      $("body").removeClass("add");
    },
  },
};
</script>