var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"wrapper"},[_c('Head'),(_vm.Member)?_c('section',{staticClass:"main case_list"},[_c('div',{staticClass:"container small"},[_c('div',{staticClass:"title"},[_c('h1',[_vm._v(_vm._s(_vm.forView(_vm.CaseServiceListData.CaseInfo.CaseInfoId))+" 勤務登記列表")])]),_c('hr'),_c('div',{staticClass:"text_none show"},[_c('div',{staticClass:"CheckAll",staticStyle:{"justify-content":"end"}},[((_vm.CaseServiceListData.CaseInfo.Status == 0 || _vm.CaseServiceListData.CaseInfo.Status == 1) &&
                _vm.CaseServiceListData.CaseInfo.Official_Status == 6 &&
                (_vm.CaseServiceListData.Member.Role.indexOf('外勤人員') != -1 && _vm.CaseServiceListData.CaseInfo.Outsider == _vm.CaseServiceListData.Member.MId)
              )?_c('router-link',{attrs:{"to":{ path: '/CaseService', query: { CIId: _vm.CaseServiceListData.CaseInfo.CIId, CISLId: -1 } },"tag":"button"}},[_vm._v("新增勤務登記")]):_vm._e()],1),_vm._l((_vm.CaseInfoServiceLogs),function(CaseInfoServiceLog,index){return (_vm.CaseInfoServiceLogs)?_c('div',{key:index,staticClass:"box show"},[_c('ul',{staticClass:"text_box"},[_c('li',{staticClass:"text"},[_c('p',[_vm._v("登記人")]),_c('p',[_vm._v(_vm._s(CaseInfoServiceLog.Member.Name)+"("+_vm._s(CaseInfoServiceLog.Member.Account)+")")])]),_c('li',{staticClass:"text"},[_c('p',[_vm._v("出勤日期")]),_c('p',[_vm._v(_vm._s(_vm._f("timeString")(CaseInfoServiceLog.ServiceTime,"YYYY-MM-DD HH:mm:ss")))])]),_c('li',{staticClass:"text"},[_c('p',[_vm._v("概述")]),_c('p',[_vm._v(_vm._s(CaseInfoServiceLog.Summary))])]),_c('li',{staticClass:"text"},[_c('p',[_vm._v("狀態")]),(CaseInfoServiceLog.Status == 1)?_c('p',[_vm._v("處理中")]):_vm._e(),(CaseInfoServiceLog.Status == 2)?_c('p',[_vm._v("處理完成")]):_vm._e()]),_c('li',{staticClass:"text"},[_c('p',[_vm._v("建立時間")]),_c('p',[_vm._v(_vm._s(_vm._f("timeString")(CaseInfoServiceLog.CreateTime,"YYYY-MM-DD HH:mm:ss")))])]),_c('li',{staticClass:"text"},[_c('p',[_vm._v("打卡時間")]),(!CaseInfoServiceLog.CardTime)?_c('p',[_vm._v("未打卡")]):_c('p',[_vm._v(_vm._s(_vm._f("timeString")(CaseInfoServiceLog.CardTime,"YYYY-MM-DD HH:mm:ss")))])]),_c('li',{staticClass:"text btn_box"},[_c('p',[_vm._v("操作")]),_c('p',[(CaseInfoServiceLog.Status == 1 && (_vm.CaseServiceListData.CaseInfo.Status == 0 || _vm.CaseServiceListData.CaseInfo.Status == 1) &&
                    _vm.CaseServiceListData.CaseInfo.Official_Status == 6 &&
                    (_vm.CaseServiceListData.Member.Role.indexOf('外勤人員') != -1 && _vm.CaseServiceListData.CaseInfo.Outsider == _vm.CaseServiceListData.Member.MId)
                  )?_c('router-link',{attrs:{"to":{
                    path: '/CaseService',
                    query: { CIId: _vm.CaseServiceListData.CaseInfo.CIId, CISLId: CaseInfoServiceLog.CISLId },
                  },"tag":"button"}},[_c('img',{attrs:{"src":"/assets/img/icon_2.svg","alt":""}}),_vm._v("編輯")]):_vm._e(),(CaseInfoServiceLog.Status == 1 && (_vm.CaseServiceListData.CaseInfo.Status == 0 || _vm.CaseServiceListData.CaseInfo.Status == 1) &&
                    _vm.CaseServiceListData.CaseInfo.Official_Status == 6 &&
                    _vm.CaseServiceListData.Member.Role.indexOf('外勤人員') != -1 &&
                    _vm.CaseServiceListData.CaseInfo.Outsider == _vm.CaseServiceListData.Member.MId
                  )?_c('button',{on:{"click":function($event){return _vm.CheckInService(CaseInfoServiceLog.CISLId)}}},[_vm._v(" 打卡 ")]):_vm._e(),((_vm.CaseServiceListData.CaseInfo.Status == 2) &&
                    (_vm.CaseServiceListData.Member.Role.indexOf('話務人員') != -1 || _vm.CaseServiceListData.Member.Role.indexOf('外勤人員') != -1)
                  )?_c('button',{on:{"click":function($event){return _vm.gotoServiceView(CaseInfoServiceLog.CISLId)}}},[_c('img',{attrs:{"src":"/assets/img/icon_2.svg"}}),_vm._v("查看")]):_vm._e()],1)])])]):_vm._e()}),_c('paginate',{attrs:{"no-li-surround":false,"page-count":_vm.pageAll,"page-range":5,"margin-pages":1,"click-handler":_vm.loadList,"prev-text":'<',"next-text":'>',"prev-link-class":'page-link',"next-link-class":'page-link',"container-class":'pagination',"page-link-class":'page-link',"active-class":'active',"active-link-class":'',"hide-prev-next":false}})],2)])]):_vm._e(),_c('Footer')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }