import { render, staticRenderFns } from "./ResetPasswordF.vue?vue&type=template&id=626e1995&"
import script from "./ResetPasswordF.vue?vue&type=script&lang=js&"
export * from "./ResetPasswordF.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports